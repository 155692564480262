import React from "react"
import { FaCircle, FaRegCircle } from "react-icons/fa"
import Layout from "../components/layout"
import ScrollAnimation from "react-animate-on-scroll"
import SEO from "../components/seo"
import EngineeringChallengeForm from "../components/EngineeringChallengeForm"
import "../styles/engineering-challenge.scss"

//Image
import MapImg from "../images/map.png"

const EngineeringChallenge = () => {
  return (
    <Layout>
      <SEO
        title="Engineering Challenge"
        description=""
        keywords={["", "", ""]}
        lang="en-gb"
      />
      {/*********** Contact *************/}
      <div className="challenge-bkg">
        <div className="eng-chal-content">
          <div className="transparent">
            <div className="outer-container">
              <div className="inner-container">
                <div className="column">
                  <h1>Challenge Us</h1>
                  <h2
                    style={{
                      width: "100%",
                      textTransform: "none",
                      fontWeight: "500",
                    }}
                  >
                    Do you have a software engineering challenge you haven't
                    been able to solve?
                  </h2>
                  <div
                    id="engineering-challenge"
                    style={{ paddingTop: "0px", paddingBottom: "0px" }}
                  >
                    <h3 style={{ textTransform: "none" }}>
                      Metsi regularly solves the toughest challenges in
                      enterprise IT. Submit your challenge here:
                    </h3>

                    <EngineeringChallengeForm />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default EngineeringChallenge
