import React, { useState } from "react"
import ButtonB from "./ButtonB"
import { navigate } from "gatsby"
import "../styles/ContactForm.scss"
import ReCAPTCHA from "react-google-recaptcha"
import { Link } from "gatsby"

const RECAPTCHA_KEY = process.env.GATSBY_APP_SITE_RECAPTCHA_KEY
  ? process.env.GATSBY_APP_SITE_RECAPTCHA_KEY
  : process.env.GATSBY_RECAPTCHA_KEY

const EngineeringChallengeForm = () => {
  const [align, setAlign] = useState(true)
  return (
    <div
      className="inner-container"
      style={{
        display: "flex",
        // justifyContent: "center",
        // textAlign: "center",
      }}
    >
      <style dangerouslySetInnerHTML={{ __html: "\n\n" }} />

      <form
        name="engineering-form-uk"
        method="post"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        className="form column"
        //onSubmit={handleSubmit}
        action="/thankyou/"
        data-netlify-recaptcha="true"
      >
        <input type="hidden" name="bot-field" />
        <input type="hidden" name="form-name" value="engineering-form-uk" />

        <div className="row">
          <div className="column">
            <label htmlFor="">Name *</label>
            <input
              name="name"
              type="text"
              tabIndex={1}
              required
              className="form-input"
              placeholder="Name"
            />
          </div>
          <div className="column">
            <label htmlFor="">Phone Number</label>
            <input
              name="phone"
              type="tel"
              tabIndex={2}
              className="form-input"
              placeholder="Phone Number"
            />
          </div>
        </div>
        <div className="row">
          <div className="column">
            <label htmlFor="">Email *</label>
            <input
              name="email"
              type="email"
              tabIndex={3}
              required
              className="form-input"
              placeholder="Email"
            />
          </div>
        </div>
        <label htmlFor="">Submit your challenge here *</label>
        <textarea
          name="Challenge"
          tabIndex={4}
          required
          className="form-text-area"
          defaultValue={""}
          placeholder="My software engineering challenge is..."

        />

        <div className="row">
          <label style={{ fontSize: "14px", color: "#626366" }}>
            <input
              type="checkbox"
              id="popi"
              name="popi"
              value="popi"
              required
              style={{
                padding: "0",
                margin: "0",
                verticalAlign: "bottom",
                position: "relative",
                top: "11px",
              }}
            />
            <span style={{ fontWeight: "300" }}>
              {" "}
              &nbsp; I agree and consent to the{" "}
              <Link to="/privacy" style={{ color: "#005b85" }}>
                <strong>Privacy Policy</strong>
              </Link>
              , specifically consenting to Metsi processing and disclosing my
              data and communicating with me according to that policy. I
              understand that I can view and adjust my communication preferences
              at any time.
            </span>
          </label>
        </div>

        <ReCAPTCHA sitekey={RECAPTCHA_KEY} />

        <button
          name="submit"
          type="submit"
          id="contact-submit"
          data-submit="...Sending"
          style={{
            background: "none",
            border: "none",
            marginBottom: "0rem",
          }}
        >
          <ButtonB>Submit</ButtonB>
        </button>
      </form>
    </div>
  )
}

export default EngineeringChallengeForm
